import functions from '../functions.js';
import Slider from "../components/Slider";
import { details } from './details';
import { DetailsCart } from './details-cart.js';
import flatpickr from 'flatpickr';

$(() => {
    detailsTravel.init();
})

const detailsTravel = {
    $details: null,
    $form: null,
    form: null,
    unitFade: false,
    recurringTravel: false,
    availability: {},
    params: {
        objectId: null, 
        unitId: null, 
        guests: null, 
        dates: null,
        dateFrom: null, 
        dateTo: null, 
        objectGroupId: null,
        calcErrorMessages: null,
        partnerId: null
    },
    init() {

        this.$details = $('.page-type-details');
        this.params.objectGroupId = this.$details.attr('data-objectgroupid');

        if(! this.$details[0] || this.params.objectGroupId != 6) {
            return;
        }

        this.getParams();

        this.params.objectGroupId = this.$details.attr('data-objectgroupid');
        this.params.partnerId = +this.$details.attr('data-partnerid');
        this.params.objectId = this.$details.attr('data-objectid');
        this.params.calcErrorMessages = $('[data-calcErrorMessages]')[0] ? JSON.parse($('[data-calcErrorMessages]').attr('data-calcErrorMessages')) : null;

        $('[data-availability]').each(function(index, elem) {
            let $elem = $(elem);
            let unitId = +$elem.attr('data-unitid');
            let availability = JSON.parse($elem.attr('data-availability'));

            detailsTravel.availability[unitId] = availability;
        });

        if (this.availability)
        {
            $('.add-to-cart').on('click', e => {
                let unitId = +$(e.currentTarget).attr('data-unitid');
                let dateFrom = $(e.currentTarget).attr('data-dateFrom');
                let dateTo = $(e.currentTarget).attr('data-dateTo');

                const $departure = $('.departure[data-datefrom=' + dateFrom + ']');
                let availbleUnits = $departure.attr('data-availbleUnits') ? JSON.parse($departure.attr('data-availbleUnits')) : [];

                availbleUnits.forEach((item, index) => {
                    if (item.unitId == unitId)
                    {
                        availbleUnits[index].tempQuantity--;
                        if (availbleUnits[index].tempQuantity < 1)
                        {
                            const $unit = $('.unit[data-unitid=' + unitId + ']');
                            $unit.find('.add-to-cart').addClass('d-none');
                            $unit.find('.unit-error-container').removeClass('d-none');
                            $unit.find('.availablility-label.btn-link').removeClass('d-none');
                        }
                    }
                    
                });
                $departure.attr('data-availbleUnits', JSON.stringify(availbleUnits));
            });


            $(document).on('click', '.cart-remove-item', e => {
                let unitId = +$(e.currentTarget).attr('data-unitid');
                let dateFrom = $(e.currentTarget).attr('data-dateFrom');
                let dateTo = $(e.currentTarget).attr('data-dateTo');

                const $departure = $('.departure[data-datefrom=' + dateFrom + ']');
                let availbleUnits = $departure.attr('data-availbleUnits') ? JSON.parse($departure.attr('data-availbleUnits')) : null;
                
                availbleUnits.forEach((item, index) => {
                    if (item.unitId == unitId) 
                    {    
                        availbleUnits[index].tempQuantity++;
                        if(availbleUnits[index].tempQuantity > availbleUnits[index].quantity) availbleUnits[index].tempQuantity == availbleUnits[index].quantity;


                        if (availbleUnits[index].tempQuantity > 0)
                        {
                            const $unit = $('.unit[data-unitid=' + unitId + ']');
                            $unit.find('.add-to-cart').removeClass('d-none');
                            $unit.find('.unit-error-container').addClass('d-none');
                            $unit.find('.availablility-label.btn-link').addClass('d-none');
                        }
                        
                    }
                });

                $departure.attr('data-availbleUnits', JSON.stringify(availbleUnits));
            });
        }

        let $summaryCardWrap = $('.card-summary-wrap');

        if ($summaryCardWrap.length) 
        {
            let height = $summaryCardWrap.outerHeight() - ($('.main-object-title').outerHeight() + $('.main-object-meta').outerHeight());
            if (height > 0) $summaryCardWrap.css({'margin-top': -height}).removeClass('opacity-0');
            
        }

        if ($('.datepicker-range-recurring').length) details.recurringTravel = true;

        // if(this.params.guests) {
        //     $('[name=guests]').val(this.params.guests);
        //     $('.input-guests')[0]._inputGuests.elemChange($('.input-guests')[0]._inputGuests);

        //     this.params.guests = this.params.adultsOnly ? this.params.guests.split(',')[0] + ',0' : this.params.guests;

        //     $('[name=guests]').each((index, elem) => {
        //         $(elem).val(this.params.guests);

        //         let guests = this.params.guests.split(',');
        //         let i = 1;
        //         for (let index = 2; index < guests.length; index++) {
        //             if(guests[index]) {
        //                 $('[name=child-age-'  + i + ']').val(guests[index]);
        //             }
        //             i++;
        //         }
        //     });
            
        // }
        let $summaryTitle = $('.summary-large b:contains(VAŽNE INFORMACIJE), .summary-large :header:contains(VAŽNE INFORMACIJE)');
        if ($summaryTitle.length) 
        {
            $summaryTitle.html('<div class="row gx-2 summary-title-row"><div class="col-auto d-flex"><i class="las la-info-circle"></i></div><div class="col-auto">' + $summaryTitle.html() + '</div>' );
        }

        $('.departure').each((index, item) => {
            const $departure = $(item);
            let maxPersonsUnits = $departure.attr('data-maxPersonsUnits') ? JSON.parse($departure.attr('data-maxPersonsUnits')) : null;
            let availableUnits = [];
            
            if(maxPersonsUnits) {
                Object.entries(maxPersonsUnits).forEach(item => {
                    const unitId = item[0];
                    const quantity = item[1];
                    const $unit = $('.unit[data-unitid="' + unitId + '"]');
                    const isZeroNights = $unit.attr('data-isZeroNights');
                    const isExcursion = $unit.attr('data-isExcursion');
                    
                    const unitMaxPersons = $unit.attr('data-maxpersons');
                    const unitsQuantity = isZeroNights || isExcursion ? (+quantity) : Math.round((+quantity) / (+unitMaxPersons));

                    availableUnits.push({'unitId': unitId, 'quantity': unitsQuantity, 'tempQuantity': unitsQuantity});
                });
    
                $departure.attr('data-availbleUnits', JSON.stringify(availableUnits));
            }
        });

        if (details.recurringTravel)
        {
            let displayDate = new Date('2019-06-01');
            let now = functions.cleanDate(new Date);
            if ( now > displayDate ) {
                displayDate = now;
            }
            $('.datepicker-range-recurring').each((i, elem) => {
                const $datepicker = $(elem);
                const minDate = $datepicker.attr('data-min');
                const maxDate = $datepicker.attr('data-max');
                const arrival = $datepicker.attr('data-arrival') ? $datepicker.attr('data-arrival') : [];
                const defaultDate = $datepicker.attr('data-defaultDate') ? $datepicker.attr('data-defaultDate') : [];
                const disable = $datepicker.attr('data-disable') ? JSON.parse($datepicker.attr('data-disable')) : [];
                const duration = Number($datepicker.attr('data-duration')) - 1;
        
        
                $datepicker.flatpickr({
                    mode: "range",
                    showMonths: functions.isMobile() ? 1 : 2,
                    altInput: true,
                    wrap: true,
                    altFormat: "j.m.Y",
                    dateFormat: "Y-m-d",
                    disable: disable,
                    // defaultDate: defaultDate,
                    minDate: defaultDate,
                    maxDate: maxDate ? maxDate : null,
                    disableMobile: true,
                    prevArrow: '<i class="la la-arrow-circle-left la-fw la-2x pr-2"></i>',
                    nextArrow: '<i class="la la-arrow-circle-right la-fw la-2x pl-2"></i>',
                    locale: {
                        rangeSeparator: ' - '
                    },
                    onDayCreate(dObj, dStr, fp, dayElem){
        
                        let to = new Date(maxDate);
                        let weekday =  new Date(dayElem.dateObj).getDay();
                        weekday = '' + (weekday ? weekday : 7);
                        to.setHours(0, 0, 0, 0);
        
                        if ( dayElem.dateObj > to || (arrival.length && $.inArray( weekday , arrival ) == -1) ) {
                            dayElem.classList.add('unselectable');
                        }
                    },
                    onChange: function(selectedDates, dateStr, instance) {
                        // duration
                        if (selectedDates[0] && ! selectedDates[1] && duration) {
                            let date = new Date(selectedDates[0]);
                            date.setHours(0);
                            date.setMinutes(0);
                            date.setSeconds(0);
                            date.setMilliseconds(0);
        
                            let date2 = new Date(selectedDates[0]);
                            date2.setHours(0);
                            date2.setMinutes(0);
                            date2.setSeconds(0);
                            date2.setMilliseconds(0);
                            date2.setDate(date.getDate() + duration);
        
                            function pad(s) { return (s < 10) ? '0' + s : s; }
                            date = new Date(date);
                            date = [date.getFullYear(), pad(date.getMonth()+1), pad(date.getDate())].join('-');
        
                            date2 = new Date(date2);
                            date2 = [date2.getFullYear(), pad(date2.getMonth()+1), pad(date2.getDate())].join('-')
        
                            instance.setDate(date + ' - ' + date2);
                            instance.close();

                            details.params.dateFrom = date;
                            details.params.dateTo = date2;
                            functions.setUrlData(details.params, true, true);
                        }
                    },
                });
                // to style clear toggler
                $datepicker.on('change', e => requestAnimationFrame(() => $(e.target).parent().toggleClass('datepicker-value', !!e.target.value)));
            });

            this.setRateReccuring();

            $('.no-period-label').removeClass('d-none');
            
        }


        ! details.recurringTravel && this.initDepartureCalendar();
        ! details.recurringTravel &&  this.setDefaultDeparture();

        this.setGuestsInstances();

        (! this.params.guests || ! this.params.dateFrom) && ! details.recurringTravel && this.setUnitRates();

        this.getCalculation();

        $('.departure').on('click', e => {
            $('.departure').removeClass('active');

            const $target = $(e.currentTarget);
            $target.addClass('active');
            this.params.dateFrom = $target.attr('data-datefrom');
            $('[data-datefrom=' + this.params.dateFrom + ']').addClass('active');
            this.params.dateTo = $target.attr('data-dateto');
            this.unitFade = true;
            this.printDates();

            functions.setUrlData({'dateFrom': this.params.dateFrom, 'guests': this.params.guests }, true, true);
            $('#booking')[0].scrollIntoView();
        });

        $('.departure-sm').on('click', e => {
            const $target = $(e.currentTarget);
            this.params.dateFrom = $target.attr('data-datefrom');

            functions.setUrlData({'dateFrom': this.params.dateFrom, 'guests': this.params.guests }, true, true);

            functions.scrollToElement('#booking', 0);
        });

        $('.price-wrap').find('[name=guests]').on('change', e => {
            e.preventDefault();
            const $target = $(e.currentTarget);
            this.params.unitId = $target.attr('data-unitid');
            this.params.guests = $target.val();

            // functions.setUrlData({'dateFrom': this.params.dateFrom, 'guests': this.params.guests }, true, true);
            this.getCalculation();
        });

        this.$cart = $('.details-cart');
        this.cart = this.$cart.length ? new DetailsCart(this.params) : [];

        $(window).on('hashchange', e => {
            this.getParams();
            ! details.recurringTravel && this.setDeparture();
            this.setGuestsInstances();
            (! this.params.guests || ! this.params.dateFrom) && ! details.recurringTravel && this.setUnitRates();
            this.getCalculation();
        });

        $('.btn-print').on('click', e => {
            const params = $('.btn-print').attr('data-params');

            if(params) {
                const url = location.protocol + '//' + location.hostname + '/services/pdf/' + params;
                window.open(url, '_blank').focus();
            }
        });

        $('.btn-booking').on('click', e => {
            if ( !this.cart.cartItems.length ) {
                $('#booking')[0].scrollIntoView();
            }
        });

    },
    initDepartureCalendar() {
        const $bookingCalendar = $('#booking-calendar-slider');

        if($bookingCalendar[0]) {
            const length = $bookingCalendar.find('.keen-slider__slide').length;
            const perView = 6;
            
            let slidesPerView = length < perView ? length : perView;

            if(length < perView) {
                $('.booking-calendar-wrapper').addClass('col-12');
                $('#booking-calendar-slider').addClass('justify-content-xl-center');
                slidesPerView = 6;
                // $('.booking-calendar-wrapper').addClass('col-lg-' + (slidesPerView + 3));
                // $('.booking-calendar-wrapper').addClass('mx-auto');
            }
    
            this.slider = new Slider($bookingCalendar, {
                // mode: 'snap',
                //centered: true,
                duration: 200,
                fade: false,
                loop: false,
                controls: false,
                initial: 0,
                arrows: functions.isMobile() || length > slidesPerView,
                slides: {
                    perView: slidesPerView,
                    spacing: 5,
                },
                breakpoints: {
                    '(min-width: 577px) and (max-width: 1024px)': {
                        controls: true,
                        arrows: length > 6,
                        //centered: true,
                        slides: {
                            perView: 4.2,
                        },
                    },
                    '(max-width: 576px)': {
                        controls: true,
                        arrows: length > 2,
                        //centered: true,
                        slides: {
                            perView: 2.2,
                        },
                    },
                },
            });
        }
    },
    setDefaultDeparture() {
        this.params.dateFrom = ! this.params.dateFrom ? $('[data-datefrom]').attr('data-datefrom') : this.params.dateFrom;
        $('[data-datefrom=' + this.params.dateFrom + ']').addClass('active');
        
        this.slider.instance.moveToIdx($('[data-datefrom=' + this.params.dateFrom + ']').parent().index());
        this.params.dateTo = $('[data-datefrom=' + this.params.dateFrom + ']').attr('data-dateto');

        this.params.guests = $('[name=guests]').val(); 

        functions.setUrlData({'dateFrom': this.params.dateFrom, 'guests': this.params.guests }, true, true);

        this.printDates();
    },
    setDeparture() {
        if(this.params.dateFrom) {
            $('.departure').removeClass('active');
            $('.departure-sm').removeClass('active');

            $('[data-datefrom=' + this.params.dateFrom + ']').addClass('active');
            this.params.dateTo = $('[data-datefrom=' + this.params.dateFrom + ']').attr('data-dateto');
            
            this.printDates();
        } else {
            this.setDefaultDeparture();
        }
    },
    printDates() {
        $('.date-from').text(functions.formatTravelPeriod(this.params.dateFrom, this.params.dateTo));
        // $('.date-to').text(functions.formatTravelPeriod(this.params.dateTo));
    },
    setUnitRates(forUnitId = null) {
        if(this.params.dateFrom) {
            let $loader = $('.unit[data-unitid=' + forUnitId + ']').find('.unit-spinner') || null;
            this.loader($loader);

            setTimeout(() => {
                const $departure = $('.departure[data-datefrom=' + this.params.dateFrom + '][data-rates]');
                const rates = $departure.attr('data-rates') ? JSON.parse($departure.attr('data-rates')) : null;

                let excludedUnits = [];

                $('.unit').each((index, elem) => {
                    const unitIds = Object.keys(rates);
                    const unitId = $(elem).attr('data-unitid');

                    ! unitIds.includes(unitId) && excludedUnits.push(unitId);
                });

                if(excludedUnits.length) {
                    excludedUnits.forEach(unitId => {
                        if (forUnitId && forUnitId != unitId ) return;
                        const $unit = $('.unit[data-unitid="' + unitId + '"]');
                        $unit.find('.unit-price').addClass('d-none');
                        $unit.find('.no-period-label').addClass('d-none');
                        $unit.find('.unit-price-label').addClass('d-none');
                        $unit.find('.availablility-label').removeClass('d-none');
                        $unit.find('.form-field.guests').addClass('d-none');
                        $unit.find('.inquiry-modal').removeClass('d-none');

                    });
                }

                // $('.unit').attr('style', 'order: 10000');
                // $('.unit').find('.unit-price-label').addClass('d-none');

                Object.entries(rates).forEach(item => {
                    const unitId = item[0];
                    const rate = item[1];
                    if (forUnitId && forUnitId != unitId ) return;

                    const $unit = $('.unit[data-unitid="' + unitId + '"]');
                    
                    $unit.find('.unit-price').removeClass('d-none');

                    $unit.find('.unit-price-old').text(rate.priceWithDiscount ? rate.price : '');
                    $unit.find('.unit-price-total').text(rate.priceWithDiscount ? rate.priceWithDiscount : rate.price);
    
                    $unit.find('.unit-price-old-eur').text(rate.priceWithDiscountEUR ? rate.priceEUR : '');
                    $unit.find('.unit-price-total-eur').text(rate.priceWithDiscountEUR ? rate.priceWithDiscountEUR : rate.priceEUR);
    
                    $unit.find('.period').text(details.params.dateFrom + '-' + details.params.dateTo);
                    $unit.find('.fm-discount').toggleClass('d-none', ! rate.discountTitle);
                    $unit.find('.unit-error-container').addClass('d-none');
                    $unit.find('.fm-discount-title').text(rate.discountTitle ? rate.discountTitle : '');
                    $unit.find('.fm-discount-valid-until').toggleClass('d-none', ! rate.validUntil);
                    $unit.find('.fm-discount-valid-until-date').text(rate.validUntil ? rate.validUntil : '');

                    let price = rate.priceWithDiscount ? rate.priceWithDiscount : rate.price;
                    price = +price.replace('€ ', '');

                    $unit.attr('style', 'order: ' + price);

                    $unit.find('.no-period-label').removeClass('d-none');
                    $unit.find('.availablility-label').addClass('d-none');
                    $unit.find('.form-field.guests').removeClass('d-none');


                    $unit.find('.unit-price-label').removeClass('d-none');
                });
            }, $loader ? 250 : 800);
        }
    },
    setGuestsInstances() {
        const $departure = $('.departure[data-datefrom=' + this.params.dateFrom + ']');
        let maxPersonsUnits = $departure.attr('data-maxPersonsUnits') ? JSON.parse($departure.attr('data-maxPersonsUnits')) : null;
        
        if(maxPersonsUnits) {
            Object.entries(maxPersonsUnits).forEach(item => {
                const unitId = item[0];
                const quantity = item[1];
    
                $('.input-guests[data-unitid="' + unitId + '"]').each((i, elem) => {
                    const instance = elem._inputGuests; 
                    instance.max = quantity ? quantity : instance.max;
                    instance.checkUpdateBtns(instance, 'adults');
                });
            });
        }
    },
    getParams() {
        this.params = details.getParams(this.params);
    },
    getCalculation() {
        
        const calculation = details.getCalculation(this.params);
        if(calculation) {
            this.loader(this.params.unitId ? $('.unit[data-unitid=' + this.params.unitId + ']').find('.unit-spinner') : null);
            calculation
                .done(response => {
                    this.calculationDone(response);
                }).fail(error => {
                    console.log(error);
                });
        } else {

            //this.setPrices($('.departure[data-datefrom=' + this.params.dateFrom + '][data-dateto=' + this.params.dateTo + ']'));
            ! details.recurringTravel && this.setUnitRates(this.params.unitId);

            let $unit = $('.unit[data-unitid=' + this.params.unitId + ']');
            
            $unit.find('.unit-price-total-label').addClass('d-none');

            $unit.find('.unit-price').addClass('d-none');
            $unit.find('.unit-price-total').removeClass('d-none');

            $unit.find('.unit-price-discount-title').addClass('d-none');
            $unit.find('.unit-price-payment-key').removeClass('d-none');

            $unit.find('.fm-discount').addClass('d-none');
            
            $unit.find('.fm-discount-valid-until').addClass('d-none');
            $unit.find('.inquiry-modal').addClass('d-none');

            $unit.find('.btn-booking').addClass('d-none');

            $unit.find('.add-to-cart').addClass('d-none');

            $unit.find('.no-period-label').removeClass('d-none');
            $unit.find('.unit-price-label').removeClass('d-none');

            if (details.recurringTravel)
            {
                this.setRateReccuring(this.params.unitId);
                $unit.find('.unit-error-container').addClass('d-none');
            }

        }
    },
    calculationDone(response) {
        if(response.status) {
            const data = response.data || [];

            //console.log(data);
            // $('.unit').attr('style', 'order: 10000');

            Object.entries(data).forEach(unit => {
                const unitId = unit[0];
                const unitData = unit[1];
                const $unit = $('.unit[data-unitid=' + unitId + ']');
                const $addToCart = $unit.find('.add-to-cart');
                const minPersons = +$unit.attr('data-minpersons');
                const maxPersons = +$unit.attr('data-maxpersons');

                let discountTitle = null;

                const unitCalc = unitData.calc;
                let persons = unitCalc.guests.split(',');
                persons = +persons[0] + +persons[1];
                let isBookable = details.isBookable(unitData) && persons >= minPersons && persons <= maxPersons;


                const $departure = $('.departure[data-datefrom=' + this.params.dateFrom + ']');
                let availbleUnits = $departure.attr('data-availbleUnits') ? JSON.parse($departure.attr('data-availbleUnits')) : null;
                let unitQuantity = availbleUnits && availbleUnits.filter(item => {
                    return item.unitId == unitId;
                })[0] || null;

                isBookable = ! unitQuantity || ! unitQuantity.tempQuantity ? false : isBookable;

                if(isBookable) {
                    const calcItems = unitCalc.items;
                    const total = unitCalc.total;
                    const currencySymbol = unitCalc.currencySymbol;

                    let priceOld = details.getCalcTotalOld(calcItems);
                    priceOld = +priceOld.toFixed(2);
                    discountTitle = details.getCalcDiscountTitle(calcItems);

                    if(discountTitle) {
                        $unit.find('.fm-discount-title').removeClass('d-none');
                        $unit.find('.unit-price-discount-title').text(discountTitle);
                        $unit.find('.fm-discount-title').text(discountTitle ? discountTitle : '');
                    }
                    else
                    {
                        $unit.find('.fm-discount-title').addClass('d-none');
                    }

                    $unit.find('.unit-price-old').text(priceOld > total ? currencySymbol + ' ' + functions.formatMoney(priceOld, 0, '.', ',') : '');

                    $unit.find('.unit-price-total-label').removeClass('d-none');
                    $unit.find('.unit-price-total').text(currencySymbol + ' ' + functions.formatMoney(total, 0, '.', ','));

                    // $unit.prop('style', 'order: ' + parseInt(total));

                    $addToCart.attr('data-unitcarttotal', total);
                    $addToCart.attr('data-unitcarttotalold', priceOld);
                    $addToCart.attr('data-currencysymbol', currencySymbol);

                    $addToCart.attr('data-datefrom', this.params.dateFrom);
                    $addToCart.attr('data-dateto', this.params.dateTo);
                    $addToCart.attr('data-guests', this.params.guests);

                    const $btnBooking = $('.btn-booking');

                    // let cartData = [];

                    // cartData.push({
                    //     'unitId': unitId,
                    //     'dateFrom': this.params.dateFrom,
                    //     'dateTo': this.params.dateTo,
                    //     'guests': this.params.guests,
                    // }); 

                    // if ($btnBooking.attr('href')) {
                    //     $btnBooking.attr('href',
                    //         $btnBooking.attr('href').split('?')[0] +
                    //         '?unitId=' + unitId +
                    //         '&dateFrom=' + this.params.dateFrom +
                    //         '&dateTo=' + this.params.dateTo +
                    //         '&guests=' + this.params.guests + '&objectGroupId=6&addAllOptionals=true'
                    //     );
                    // }

                    $unit.find('.unit-price-total-label').removeClass('d-none');

                    $unit.find('.unit-price').removeClass('d-none');

                    $unit.find('.unit-price-discount-title').removeClass('d-none');
                    $unit.find('.unit-price-payment-key').addClass('d-none');

                    $unit.find('.fm-discount').removeClass('d-none');
                    
                    $unit.find('.fm-discount-valid-until').addClass('d-none');

                    $unit.find('.btn-booking').removeClass('d-none');

                    $addToCart.length && $addToCart.removeClass('d-none');


                    $unit.find('.unit-error-container').addClass('d-none');
                    $unit.find('.unit-error-container').text('');

                    $unit.find('.no-period-label').addClass('d-none');
                    $unit.find('.unit-price-label').addClass('d-none');

                    $unit.find('.inquiry-modal').addClass('d-none');

                    $btnBooking.removeClass('d-none');

                    $btnBooking.on('click', e => {
                        e.preventDefault();
                        let cartData = [];

                        this.cart.cartItems.forEach(item => {
                            item = JSON.parse(item);
                            cartData.push({
                                'unitId': item.unitId,
                                'dateFrom': item.dateFrom,
                                'dateTo': item.dateTo,
                                'guests': item.guests,
                            }); 
                        });

                        let url = $(e.currentTarget).attr('data-href') + '?cart_data=' + JSON.stringify(cartData) + '&addAllOptionals=true&partnerId=' + detailsTravel.params.partnerId;
                        
                        if(this.cart.cartItems.length) {
                            location = url;
                        } else {
                            $('#detailsCartModal').modal('show');
                            setTimeout(() => {
                                $('#detailsCartModal').modal('hide');
                            }, 2500);
                        }
                    });
                    
                }

                else
                {
                    $unit.find('.unit-price-total-label').addClass('d-none');

                    $unit.find('.unit-price').addClass('d-none');

                    $unit.find('.unit-price-discount-title').addClass('d-none');
                    $unit.find('.unit-price-payment-key').removeClass('d-none');

                    $unit.find('.fm-discount').addClass('d-none');
                    
                    $unit.find('.fm-discount-valid-until').removeClass('d-none');
                    $unit.find('.inquiry-modal').removeClass('d-none');

                    $unit.find('.btn-booking').addClass('d-none');

                    $addToCart.length && $addToCart.addClass('d-none');

                    let error = details.getCalcErrorMessage(this.params.calcErrorMessages, unitData);
                    $unit.find('.unit-error-container').removeClass('d-none');
                    $unit.find('.unit-error-container').text(error);

                    $unit.find('.no-period-label').addClass('d-none');
                    $unit.find('.unit-price-label').addClass('d-none');

                    
                }


                // $unit.find('.unit-price-total-label').toggleClass('d-none', ! isBookable);

                // $unit.find('.unit-price').toggleClass('d-none', ! isBookable);

                // $unit.find('.unit-price-discount-title').toggleClass('d-none', ! isBookable);
                // $unit.find('.unit-price-payment-key').toggleClass('d-none', isBookable);

                // $unit.find('.fm-discount').toggleClass('d-none', ! isBookable);
                
                // $unit.find('.fm-discount-valid-until').toggleClass('d-none', isBookable);

                // $unit.find('.btn-booking').toggleClass('d-none', ! isBookable);

                // $addToCart.length && $addToCart.toggleClass('d-none', ! isBookable);

                // let error = details.getCalcErrorMessage(this.params.calcErrorMessages, unitData);

                // $unit.find('.unit-error-container').toggleClass('d-none', isBookable);
                // $unit.find('.unit-error-container').text(error);

                // $unit.find('.no-period-label').toggleClass('d-none', ! isBookable);
                // $unit.find('.unit-price-label').toggleClass('d-none', ! isBookable);
                
            });
        }
    },
    loader($target = null) {
        this.unitFade == true ? $('.units').addClass('fade') : '';
        $target ? $target.addClass('active') : $('.unit-spinner').addClass('active');
        setTimeout(() => {
            $target ? $target.removeClass('active') : $('.unit-spinner').removeClass('active');
        }, 600);
        setTimeout(() => {
            this.unitFade == true ? $('.units').removeClass('fade') : '';
            this.unitFade = false;
        }, 800);
    },
    setRateReccuring(unitId = null) 
    {
        $('.unit.card').each((index, elem) => {
            let $elem = $(elem);
            let currUnitId = $elem.attr('data-unitid');

            if (unitId && unitId !== currUnitId ) return;

            $elem.find('.unit-price').removeClass('d-none');
            // $elem.find('.unit-error-container').addClass('d-none');

            let priceOld = $elem.find('.unit-price-old').attr('data-unitpriceold');
            let price = $elem.find('.unit-price-total').attr('data-unitpricetotal');
            
            if (priceOld != '€ 0') $elem.find('.unit-price-old').text(priceOld);
            if (price != '€ 0') $elem.find('.unit-price-total').text(price);

        })
    }
};
